import Swiper, { Autoplay, Navigation, Thumbs } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const thumbsSliders = document.getElementsByClassName(`thumbs-slider`)[0];

if (thumbsSliders) {
  const thumbsSwiperBottom = new Swiper('.thumbs-slider-bottom', {
    modules: [Autoplay, Navigation, Thumbs],
    spaceBetween: 8,
    slidesPerView: 3,

    breakpoints: {
      768: {
        spaceBetween: 14,
        slidesPerView: 3,
      },
      1024: {
        spaceBetween: 16,
        slidesPerView: 3,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
  });

  const thumbsSwiperTop = new Swiper('.thumbs-slider-top', {
    modules: [Autoplay, Navigation, Thumbs],
    spaceBetween: 20,
    /*navigation: {
      nextEl: ".thumbs-slider__next",
      prevEl: ".thumbs-slider__prev",
    },
    pagination: {
      el: ".thumbs-slider .swiper-pagination",
      type: "fraction",
      formatFractionCurrent: function (number) {
        return number < 10 ? "0" + number : number;
      },
      formatFractionTotal: function (number) {
        return number < 10 ? "0" + number : number;
      },
    },*/
    thumbs: {
      swiper: thumbsSwiperBottom,
    },
  });

  thumbsSwiperBottom.slides.forEach((elem, index) => {
    elem.addEventListener('click', (e) => {
      thumbsSwiperTop.slideTo(index);
    });
  });
}
