const faqAccordion = document.getElementsByClassName(
  'faq-section__accordion'
)[0];

if (faqAccordion) {
  const faqItemsArr = faqAccordion.querySelectorAll('.faq-section__item');

  faqItemsArr.forEach((item, i) => {
    const btn = item.querySelector('.faq-section__item-btn');
    const content = item.querySelector('.faq-section__item-content');

    item.addEventListener('click', (event) => {
      if (event.target !== btn) {
        openAccordion(item);
      } else if (event.target === btn && item.classList.contains('active')) {
        item.classList.remove('active');
      } else {
        openAccordion(item);
      }
    });

    function openAccordion(item) {
      for (let j = 0; j < faqItemsArr.length; j++) {
        faqItemsArr[j].classList.remove('active');
      }

      item.classList.add('active');
      content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
    }

    function itemCalcMaxHeight(item, content) {
      const children = Array.from(content.children);
      const totalHeight = children.reduce((acc, child) => {
        const childHeight = child.offsetHeight;
        return acc + childHeight;
      }, 0);
      return totalHeight + 100;
    }
  });
}
