import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('testimonials-section__slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll(
    '.testimonials-section__slider'
  );
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 'auto',
      spaceBetween: 12,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.swiper-buttons .swiper-button-prev'),
        nextEl: sliderEl.querySelector('.swiper-buttons .swiper-button-next'),
      },
      breakpoints: {
        651: {
          spaceBetween: 16,
        },
        1200: {
          spaceBetween: 24,
        },
      },
    });

    swiperObserver(testimonialsSwiper);
  });
}
