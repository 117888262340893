import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';
/*import './components/buttons.component.js';*/
import './components/cart.component.js';
import './components/home-first.component.js';
import './components/products.component.js';
import './components/marquee.component.js';
import './components/product-detail.component.js';

/*Sliders*/

import './components/recipes.component.js';

import './components/swiper-products.component.js';
import './components/swiper-reviews.component.js';
import './components/swiper-testimonials.component.js';
import './components/swiper-choose.component.js';
import './components/swiper-catalogue.component.js';
import './components/swiper-join-us.component.js'
import './components/swiper-thumbs.component.js';
/*End Sliders*/

import './components/faq.component.js';

import './components/titles.component.js';

import './libs/countUp.lib.js';

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let target = config[0];
  let duration = config[1] && !isNaN(config[1]) ? parseInt(config[1]) : 800;
  let paddingTop = config[2] && !isNaN(config[2]) ? parseInt(config[2]) : 0;

  let targetElement =
    document.getElementById(target) || document.querySelector(target);

  if (targetElement) {
    let targetOffsetTop =
      targetElement.getBoundingClientRect().top +
      window.pageYOffset -
      paddingTop;

    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth',
    });
    return false;
  } else {
    console.error('Element not found: ' + target);
  }
};
