window.productCardsFunc = function productCardsFunc() {
  if (document.querySelector('.product-card')) {
    const productCardsArr = document.querySelectorAll('.product-card');

    productCardsArr.forEach((card) => {
      if (!card.classList.contains('is-init')) {
        card.classList.add('is-init');

        const counterBlock = card.querySelector('.product-card__btn-counter');
        const btn = card.querySelector('.btn');
        const btnIcon = card.querySelector('.btn-icon');
        const input = card.querySelector('input');
        const cardList = card.querySelector('.product-card__list');

        if (btn) {
          btn.addEventListener('click', () => {
            if (counterBlock) {
              counterBlock.classList.add('active');
              card.classList.add('is-open-counter');
            }

            if (input && input.value < 1) {
              input.stepUp();
            }
          });
        }

        if (btnIcon) {
          btnIcon.addEventListener('click', () => {
            if (counterBlock) {
              counterBlock.classList.add('active');
              card.classList.add('is-open-counter');
            }

            if (input && input.value < 1) {
              input.stepUp();
            }
          });
        }

        if (cardList) {
          setTimeout(function () {
            if (cardList.clientWidth > card.clientWidth) {
              cardList.classList.add('is-hovered');
              let diference = cardList.clientWidth - card.clientWidth;
              cardList.style.setProperty('--diference', `${diference}px`);
            }
          }, 50);
        }
      }

      /*if(card.classList.contains('is-init') && card.classList.contains('product-section__card')) {
        const counterBlock = card.querySelector('.product-card__btn-counter');
        const btn = card.querySelector('.btn');
        const btnIcon = card.querySelector('.btn-icon');
        const input = card.querySelector('input');

        if (btn) {
          btn.addEventListener('click', () => {
            if (counterBlock) {
              counterBlock.classList.add('active');
              card.classList.add('is-open-counter');
            }

            if (input && input.value < 1) {
              input.stepUp();
            }
          });
        }

        if (btnIcon) {
          btnIcon.addEventListener('click', () => {
            if (counterBlock) {
              counterBlock.classList.add('active');
              card.classList.add('is-open-counter');
            }

            if (input && input.value < 1) {
              input.stepUp();
            }
          });
        }
      }*/
    });

    setTimeout(function () {
      const productCardsTitlesArr = document.querySelectorAll(
        '.product-card__title'
      );

      let maxHeight = 0;
      productCardsTitlesArr.forEach((title) => {
        const height = title.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      productCardsTitlesArr.forEach((title) => {
        title.style.minHeight = `${maxHeight}px`;
      });
    }, 50);
  }
}

productCardsFunc();

window.productBottomCardsFunc = function productBottomCardsFunc(cardId) {
  if (document.querySelector('.product-card')) {
    const card = document.getElementById(cardId);
    const counterBlock = card.querySelector('.product-card__btn-counter');
    const btn = card.querySelector('.btn');
    const btnIcon = card.querySelector('.btn-icon');
    const input = card.querySelector('input');

    btn.addEventListener('click', () => {
      if (counterBlock) {
        counterBlock.classList.add('active');
        card.classList.add('is-open-counter');
      }

      if (input && input.value < 1) {
        input.stepUp();
      }
    });

    btnIcon.addEventListener('click', () => {
      if (counterBlock) {
        counterBlock.classList.add('active');
        card.classList.add('is-open-counter');
      }

      if (input && input.value < 1) {
        input.stepUp();
      }
    });
  }
}
