import marquee from 'vanilla-marquee';
import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.querySelector('.home-first-section__row')) {
  const marqueeArray = [];

  const marqueeArr = document.querySelectorAll('.home-first-section__row');
  marqueeArr.forEach((marqueeEl, i) => {
    const itemsArr = marqueeEl.querySelectorAll('.home-first-section__item');
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });

    const finalItemsArr = marqueeEl.querySelectorAll(
      '.home-first-section__item'
    );

    let speed = 100 / (finalItemsArr.length * finalItemsArr[0].clientHeight);

    if (window.innerWidth <= 1050 && window.innerWidth > 901) {
      speed = 90 / (finalItemsArr.length * finalItemsArr[0].clientHeight);
    }

    let direction = 'up';
    let pauseOnHover = true;

    if (window.innerWidth <= 1024) {
      pauseOnHover = false;
    }

    if (window.innerWidth <= 901) {
      direction = 'left';
      speed = 30;
      if (i % 2) {
        direction = 'right';
      }

      const mq = new marquee(marqueeEl, {
        delayBeforeStart: 0,
        direction: direction,
        pauseOnHover: pauseOnHover,
        duplicated: true,
        recalcResize: false,
        startVisible: true,
        gap: 0,
        speed: speed,
      });

      marqueeArray.push(mq);

      if (window.innerWidth <= 1024) {
        setTimeout(function () {
          const itemsArr = marqueeEl.querySelectorAll(
            '.home-first-section__item'
          );
          itemsArr.forEach((item) => {
            item.addEventListener('click', (e) => {
              console.log('click');
              if (item.classList.contains('is-hover')) {
                item.classList.remove('is-hover');
                mq.resume();
              } else {
                const newArr = document.querySelectorAll(
                  '.home-first-section__item'
                );
                for (let j = 0; j < newArr.length; j++) {
                  newArr[j].classList.remove('is-hover');
                }
                item.classList.add('is-hover');
                for (let j = 0; j < marqueeArray.length; j++) {
                  marqueeArray[j].resume();
                }
                mq.pause();
              }
            });
          });
        }, 100);
      }
    } else {
      if (i % 2) {
        direction = 'down';
      }

      const mq = new marquee(marqueeEl, {
        delayBeforeStart: 0,
        direction: direction,
        pauseOnHover: pauseOnHover,
        duplicated: true,
        recalcResize: false,
        startVisible: true,
        gap: 0,
        speed: speed,
      });

      marqueeArray.push(mq);

      if (window.innerWidth <= 1024) {
        setTimeout(function () {
          const itemsArr = marqueeEl.querySelectorAll(
            '.home-first-section__item'
          );
          marqueeEl
            .querySelectorAll('.home-first-section__item')
            .forEach((item) => {
              item.addEventListener('click', (e) => {
                console.log('click');
                if (item.classList.contains('is-hover')) {
                  item.classList.remove('is-hover');
                  mq.resume();
                } else {
                  const newArr = document.querySelectorAll(
                    '.home-first-section__item'
                  );
                  for (let j = 0; j < newArr.length; j++) {
                    newArr[j].classList.remove('is-hover');
                  }
                  item.classList.add('is-hover');
                  for (let j = 0; j < marqueeArray.length; j++) {
                    marqueeArray[j].resume();
                  }
                  mq.pause();
                }
              });
            });
        }, 100);
      }
    }
  });

  document.addEventListener('click', function (event) {
    // Перевіряємо, чи клік був поза елементом marqueeEl
    if (!marqueeArr[0].parentElement.contains(event.target)) {
      const newArr = document.querySelectorAll('.home-first-section__item');
      for (let j = 0; j < newArr.length; j++) {
        newArr[j].classList.remove('is-hover');
      }
      for (let j = 0; j < marqueeArray.length; j++) {
        marqueeArray[j].resume();
      }
    }
  });
}

if (document.getElementsByClassName('home-first-section__row-slider')[0]) {
  const expertiseSlidersArr = document.querySelectorAll(
    '.home-first-section__row-slider'
  );
  expertiseSlidersArr.forEach((sliderEl, sliderIdx) => {
    let reverseDirection = false;
    let delay = 3000;

    if (sliderIdx % 2) {
      reverseDirection = true;
      delay = 3200;
    }

    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 3) {
      loop = true;
      loopedSlides = slides.length * 4;
    }

    const expertiseSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: delay,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        reverseDirection: reverseDirection,
      },
      /*      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 1.5,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        901: {
          spaceBetween: 18,
          slidesPerView: 3,
        },
      },
    });

    swiperObserver(expertiseSwiper);

    const finalSlides = sliderEl.querySelectorAll('.swiper-slide');

    finalSlides.forEach((item, i) => {
      item.addEventListener('click', (e) => {
        if (!item.classList.contains('is-active')) {
          if (!item.classList.contains('is-unlock')) {
            e.preventDefault();
            item.classList.add('is-unlock');
          } else {
            item.classList.remove('is-unlock');
          }
          item.classList.add('is-active');
        } else {
          if (!item.classList.contains('is-unlock')) {
            item.classList.remove('is-active');
          }
        }
      });
    });
  });
}
