const cartComponent = document.getElementsByClassName('cart-container')[0];

if (cartComponent) {
  const cartOpenBtn = document.querySelector('.cart-open');
  /*const cartMobileOpenBtn = document.querySelector('.cart-open-mob');*/
  const cartCloseBg = cartComponent.querySelector('.cart-close-wrapper');
  const cartCloseMobBtn = cartComponent.querySelector('.cart__close');
  const cart = cartComponent.querySelector('.cart');

  const cartTitle = cartComponent.querySelector('.cart__title');
  const cartList = cartComponent.querySelector('.cart__list');
  /*const cartVisibleBottom = cartComponent.querySelector(
    '.cart__visible-bottom'
  );*/
  const cartResult = cartComponent.querySelector('.cart__result');

  let scrollList = null;

  let openDelay = 500;

  function openCartFunc() {
    if (!cartComponent.classList.contains('is-open')) {
      cartComponent.classList.add('is-open');
      cartComponent.style.zIndex = 1000;
      scrollListCalc();
    } else {
      cartComponent.classList.remove('is-open');

      setTimeout(function () {
        cartComponent.style.zIndex = 800;
      }, openDelay);
      /*disableScrollListFunc();*/
      scrollListCalc();
    }

    // LockScroll when burger open and enable when closed and enable scroll on menu
    if (
      !document
        .getElementsByClassName('header')[0]
        .classList.contains('open-menu')
    ) {
      if (scrollLock.getScrollState()) {
        scrollLock.disablePageScroll(
          document.querySelector('.navbar-nav .menu')
        );
        scrollLock.addScrollableSelector('.simplebar-content-wrapper');
      } else {
        scrollLock.enablePageScroll();
      }
    }
  }

  if (cartOpenBtn) {

    cartOpenBtn.addEventListener('click', () => {
      if (!cartOpenBtn.classList.contains('cart-visible-page')) {
        openCartFunc();
      }
    });
  }

  /*cartMobileOpenBtn.addEventListener('click', () => {
    openCartFunc();
  });*/

  if (cartCloseBg) {
    cartCloseBg.addEventListener('click', () => {
      openCartFunc();
    });
  }

  if (cartCloseMobBtn) {
    cartCloseMobBtn.addEventListener('click', () => {
      openCartFunc();
    });
  }

  function scrollListCalc() {
    if (cartList && cartComponent.classList.contains('is-open')) {
      cartList.style.maxHeight = `calc(100% - ${
        cartTitle.clientHeight + cartResult.clientHeight
      }px)`;
    } else if (cartList && !cartComponent.classList.contains('is-open')) {
      /*cartList.style.maxHeight = `calc(100% - ${
        cartTitle.clientHeight + cartVisibleBottom.clientHeight
      }px)`;*/
    }
  }
  scrollListCalc();

  function enableScrollListFunc() {
    if (cartList) {
      scrollList = new SimpleBar(cartList);
    }
  }
  enableScrollListFunc();

  function disableScrollListFunc() {
    if (cartList) {
      scrollList.unMount();
    }
  }

  window.addEventListener('resize', scrollListCalc);
}

window.closeCartGlobal = function closeCartGlobal() {
  if (cartComponent) {
    cartComponent.classList.remove('is-open');

    setTimeout(function () {
      cartComponent.style.zIndex = 800;
    }, 500);
  }
};



window.cartCounterVisible = function cartCounterVisible() {
  const cartCounterEl = document.querySelector('.btn-icon__count');
  if(cartCounterEl) {
    cartCounterEl.classList.remove('is-hidden');
  }
}

window.cartCounterHidden = function cartCounterHidden() {
  const cartCounterEl = document.querySelector('.btn-icon__count');
  if(cartCounterEl) {
    cartCounterEl.classList.add('is-hidden');
  }
}

