const headerComponent = document.getElementsByClassName('header')[0];
const topHeaderComponent = document.getElementsByClassName('top-header')[0];
// Header scroll
const scrollContainer = () => document.documentElement || document.body;

document.addEventListener('scroll', () => {
  if (scrollContainer().scrollTop > 50) {
    headerComponent.classList.add('scrolled');
    /*topHeaderComponent.classList.add('scrolled');*/
  } else if (scrollContainer().scrollTop == 0) {
    headerComponent.classList.remove('scrolled');
    /*topHeaderComponent.classList.remove('scrolled');*/
  }
});

// menu handlers

// check mobile menu show/hide condition
const mobileMenuStartPoint = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue(
    '--mobile-menu-start-point'
  )
);
let isMobileMenuEnable =
  window.outerWidth <= mobileMenuStartPoint ||
  document.querySelector('.header-mobile');

/*const dropdownToggle = document.querySelectorAll('.menu-dropdown');

dropdownToggle.forEach((dropdown) => {
  const toggle = dropdown.querySelector('.menu-dropdown__arrow');
  toggle.addEventListener('click', function (e) {
    if (isMobileMenuEnable) {
      const menuItem = this.closest('.menu__item.menu-dropdown');
      const menuList = menuItem.querySelector('.menu-dropdown__list');
      const dropdownMenu = this.nextElementSibling;

      // close all opened sub menu
      const activeDropdowns = document.querySelectorAll(
        '.menu-item.dropdown.active .dropdown-menu'
      );
      activeDropdowns.forEach((menu) => {
        menu.style.display = 'none';
        menu.closest('.dropdown').classList.remove('active');
      });
      document.querySelectorAll('.menu-dropdown__list').forEach((list) => {
        list.classList.remove('active');
      });

      // open current submenu
      menuItem.classList.toggle('active');
      menuList.classList.toggle('active');
      if (menuItem.classList.contains('active')) {
        e.preventDefault();
        dropdownMenu.style.display = 'block';
      }
    }
  });
});*/

if (document.getElementsByClassName('menu-dropdown')[0]) {
  const dropdownToggleArr = document.querySelectorAll('.menu-dropdown');
  const dropdownArrowArr = document.querySelectorAll('.menu-dropdown__arrow');
  const dropdownListArr = document.querySelectorAll('.menu-dropdown__list');

  dropdownToggleArr.forEach((btn, i) => {
    const arrow = btn.querySelector('.menu-dropdown__arrow');
    const link = btn.querySelector('.menu__link');

    link.addEventListener('click', (e) => {
      if (!link.classList.contains('is-opened')) {
        e.preventDefault();
      }
      link.classList.add('is-opened');

      openLogic();
    });

    arrow.addEventListener('click', () => {
      if (!arrow.classList.contains('active')) {
        openLogic();
      } else {
        for (let j = 0; j < dropdownToggleArr.length; j++) {
          dropdownArrowArr[j].classList.remove('active');
          dropdownListArr[j].classList.remove('active');
        }
      }
    });

    function openLogic() {
      for (let j = 0; j < dropdownToggleArr.length; j++) {
        dropdownArrowArr[j].classList.remove('active');
        dropdownListArr[j].classList.remove('active');
      }

      arrow.classList.toggle('active');
      dropdownListArr[i].classList.toggle('active');
    }
  });
}

// toggle menu handler
function menuToggleFunc() {
  const menuToggle = document.querySelector('.menu-toggle');
  const navbarNav = document.querySelector('.navbar-nav');
  const headerCloseWrapper = document.querySelector('.header-close-wrapper');

  menuToggle.classList.toggle('active');
  navbarNav.classList.toggle('active');
  headerComponent.classList.toggle('open-menu');
  /*topHeaderComponent.classList.toggle('open-menu');*/
  headerCloseWrapper.classList.toggle('active');
  updatePositionMobileNav();

  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (
    !document
      .getElementsByClassName('cart-container')[0]
      .classList.contains('is-open')
  ) {
    if (scrollLock.getScrollState()) {
      scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
      scrollLock.addScrollableSelector('.simplebar-content-wrapper');
    } else {
      scrollLock.enablePageScroll();
    }
  }
}

// menu update function
function updateMenu() {
  isMobileMenuEnable =
    window.outerWidth <= mobileMenuStartPoint ||
    document.querySelector('.mobile-header');

  if (!isMobileMenuEnable) {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach((menu) => {
      menu.style.display = '';
    });

    const headerCloseWrapper = document.querySelector('.header-close-wrapper');
    headerCloseWrapper.classList.remove('active');

    const activeMenuItems = document.querySelectorAll('.menu-item.active');
    activeMenuItems.forEach((item) => {
      item.classList.remove('active');
    });

    const navbarNav = document.querySelector('.navbar-nav');
    navbarNav.classList.remove('active');

    headerComponent.classList.remove('open-menu');
    /*topHeaderComponent.classList.remove('open-menu');*/

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle.classList.remove('active');

    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }

  updatePositionMobileNav();
}

function updatePositionMobileNav() {
  const topNavValue = `${headerComponent.clientHeight}px`;
  document.documentElement.style.setProperty('--top-nav', topNavValue);
}

updatePositionMobileNav();

window.addEventListener('resize', updateMenu);
window.addEventListener('orientationchange', updateMenu);

// end of toggle menu handler

const menuToggle = document.querySelector('.menu-toggle');
const headerCloseWrapper = document.querySelector('.header-close-wrapper');

menuToggle.addEventListener('click', menuToggleFunc);
headerCloseWrapper.addEventListener('click', menuToggleFunc);

// Hide and show Header when scroll up
/*function headerSticky() {
  let prevScrollPos = window.pageYOffset;

  window.addEventListener('scroll', () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = headerComponent.scrollHeight;
    const topHeaderHeight = topHeaderComponent.scrollHeight;

    if (window.scrollY > 0) {
      if (prevScrollPos > currentScrollPos) {
        /!*headerComponent.style.top = 0;*!/
        headerComponent.classList.add('scrolled');
        topHeaderComponent.style.top = 0;
        topHeaderComponent.classList.add('scrolled');
        if (headerComponent.classList.contains('scrolled')) {
          headerComponent.style.top = `${topHeaderHeight}px`;
        }
      } else {
        /!*headerComponent.style.top = `-${headerHeight + 3}px`;*!/
        topHeaderComponent.style.top = `-${topHeaderHeight + 3}px`;
        headerComponent.style.top = 0;
      }
    }

    if (window.scrollY === 0) {
      headerComponent.classList.remove('scrolled');
      topHeaderComponent.classList.remove('scrolled');
      headerComponent.style.top = 0;
    }

    prevScrollPos = currentScrollPos;
  });

  if (window.scrollY !== 0) {
    headerComponent.classList.add('scrolled');
    topHeaderComponent.classList.add('scrolled');
  }
}

headerSticky();*/

function headerSticky() {
  let prevScrollPos = window.pageYOffset;

  window.addEventListener('scroll', () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = headerComponent.scrollHeight;

    if (window.scrollY > 0) {
      if (prevScrollPos > currentScrollPos) {
        headerComponent.style.top = 0;
        headerComponent.classList.add('scrolled');
      } else {
        headerComponent.style.top = `-${headerHeight + 3}px`;
      }
    }

    if (window.scrollY === 0) {
      headerComponent.classList.remove('scrolled');
      headerComponent.style.top = 0;
    }

    prevScrollPos = currentScrollPos;
  });

  if (window.scrollY !== 0) {
    headerComponent.classList.add('scrolled');
  }
}

headerSticky();

const languageDropdown = document.getElementsByClassName(
  'top-header__language'
)[0];

if (languageDropdown) {
  languageDropdown.addEventListener('click', () => {
    if (window.innerWidth <= 1024) {
      languageDropdown.classList.toggle('active');
    }
  });

  document.addEventListener('click', (event) => {
    if (window.innerWidth <= 1024) {
      const targetElement = event.target;
      const isClickInsideLanguageDropdown =
        languageDropdown.contains(targetElement);

      if (!isClickInsideLanguageDropdown) {
        languageDropdown.classList.remove('active');
      }
    }
  });
}

if (document.querySelector('.header__categories-menu')) {
  const headerCategoriesMenu = document.querySelector(
    '.header__categories-menu'
  );
  const headerCategoriesToggle = document.querySelector('.header__categories');

  headerCategoriesToggle.addEventListener('click', () => {
    if (!headerCategoriesToggle.classList.contains('active')) {
      headerCategoriesToggle.classList.add('active');
      headerCategoriesMenu.classList.add('active');
      headerComponent.classList.add('not-shadow');
    } else {
      headerCategoriesToggle.classList.remove('active');
      headerCategoriesMenu.classList.remove('active');
      headerComponent.classList.remove('not-shadow');
    }
  });
}
